import { lowerCaseFirstLetter } from './misc';

const ACTIONS = {
	CLONE: 'clone',
	CREATE: 'new',
	EDIT: 'edit',
	DETAIL: '',
	LIST: '',
};

export function typeName2ListName(typeName) {
	typeName = lowerCaseFirstLetter(typeName);
	switch (typeName[typeName.length - 1]) {
		case 's':
			return typeName + 'es';
		case 'y':
			return typeName.substr(0, typeName.length - 1) + 'ies';
		default:
			return typeName + 's';
	}
}

function generateUrl(entityName, action, entityId) {
	// if (!linkableEntities[entityName]) {
	// return; // TODO - abilitare quando ho attivato la registrazione su login/cambio utente
	// }
	let pieces = entityName.split('_', 2);
	let url = '/' + typeName2ListName(pieces[0]).toLowerCase();
	if (pieces[1]) url += '_' + pieces[1];
	if (entityId) url += '/' + encodeURIComponent(entityId);
	if (action !== ACTIONS.DETAIL) url += '/' + action;
	return url;
}

export {
	generateUrl,
	ACTIONS,
};

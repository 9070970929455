const simpleDebounce = (func, waitMilliseconds) => {
	let timeoutId;

	return (...args) => {
		const doLater = function() {
			timeoutId = undefined;
			func.apply(null, args);
		};

		if (timeoutId !== undefined) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout(doLater, waitMilliseconds);
	};
};

const stacked = (func, waitMilliseconds) => {
	let stack = [];

	const handler = simpleDebounce(
		() => stacked.length > 0 && func(stack.splice(0, stack.length)),
		waitMilliseconds,
	);

	return (event) => {
		stack.push(event);
		handler();
	};
};

export { stacked };

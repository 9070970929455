import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { GenericSpinner } from './GenericSpinner';
import { queryErrorHandler } from '../utils/errorHandlers';

// query
const WidgetPageQuery = gql`
	query WidgetPageQuery($filter: WidgetViewInput!) {
		widgets: widgetsView(filter: $filter) {
			id
			bodyRendered
			footerRendered
			titleRendered
			gridSize
		}
	}
`;

// component
const WidgetsBlock = ({
	data: { error, loading, widgets },
	PresentationalComponent,
}) => {
	if (error) return queryErrorHandler(error);

	if (loading) return <GenericSpinner />;

	return <PresentationalComponent widgets={widgets} />;
};

// TODO rimuovere questa option strana una volta che il bug di apollo è risolto
// https://github.com/apollographql/apollo-client/issues/1186
export default graphql(WidgetPageQuery, {
	options: (props) => ({
		variables: {
			filter: props.filter,
		},
		notifyOnNetworkStatusChange: true,
		// fetchPolicy: 'network-only'
	}),
})(WidgetsBlock);

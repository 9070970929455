import React, { Component } from 'react';
import {
	Button,
	Grid,
	GridColumn,
	Header,
	Icon,
	Rating,
	Segment,
	Sidebar,
} from 'semantic-ui-react';
import DOMPurify from 'dompurify';
import t from '../utils/labels';
// import { LightBoxedImage } from './lightBoxedImage';
import InnerImageZoom from 'react-inner-image-zoom';

export default class RatingBlock extends Component {
	
	_rate = (labelIndex) => (event, { rating }) => {
		const newRatings = this.state.ratings;
		newRatings[labelIndex] = rating;
		this.setState({
			ratings: newRatings,
		});
	};

	_confirmRatings = async () => {
		this._toggleLoading();
		const saveResult = await this.props.onConfirmRatings(this.state.ratings);
		if (saveResult) {
			this.setState({
				ratings: this.state.ratings.map(() => 0),
				loading: false,
			});
		} else {
			this.setState({ loading: false });
		}
	};

	_toggleLoading = () => this.setState({ loading: !this.state.loading });

	_toggleMoreInfoSidebar = () =>
		this.setState({
			moreInfoIsVisible: !this.state.moreInfoIsVisible,
		});

	_hasRatedEverything = () => {
		const award = this.props.candidate.Award;
		const requiredRatingsPosition = [1, 2, 3, 4, 5].filter(
			(index) =>
				award[`rating${index}Label`] &&
				award[`rating${index}Label`].indexOf('*') !== -1,
		);
		return (
			requiredRatingsPosition.every(
				(index) => this.state.ratings[index] > 0,
			) &&
			this.state.ratings.some((r) => r > 0) &&
			this.state.ratings[0]
		);
	};

	constructor(props) {
		super(props);

		const candidate = props.candidate;
		const award = candidate.Award;
		const overallLabel = award['ratingOverallLabel'];
		const ratingsLabel = [1, 2, 3, 4, 5].reduce(
			(list, index) => {
				const label = award[`rating${index}Label`];
				if (label !== null) list.push(label.split('|')[0]);
				return list;
			},
			[overallLabel || t`overall`],
		);
		//const awardIsIt = award.langCode === 'it_IT';

		const candidacyDescriptions = [1, 2, 3, 7, 8].reduce((list, index) => {
			if (award[`description${index}Label`])
				list.push({
					index,
					label: award[`description${index}Label`]
						.split('|')[0]
						.replace('*', ''),
				});
			return list;
		}, []);

		this.state = {
			ratingsLabel,
			ratings: ratingsLabel.map(() => 0),
			descriptions: candidacyDescriptions.filter(
				(description) => description.label.charAt(0) !== '$',
			),
			selectedImageIndex: 0,
		};
	}

	render() {
		const candidate = this.props.candidate;
		const {
			ratingsLabel,
			ratings,
			moreInfoIsVisible,
			descriptions,
			loading,
			selectedImageIndex,
		} = this.state;
		const award = candidate.Award;
		const overallLabel = award['ratingOverallLabel'];
		const awardIsIt = award.langCode === 'it_IT';
		const possibleImages = [
			candidate.featuredImageMedia,
			...candidate.goods.map((g) =>
				awardIsIt ? g.ingredientsImageItaMedia : g.ingredientsImageMedia,
			),
		];
		const selectedImg = possibleImages[selectedImageIndex];
		
		return (
			<Sidebar.Pushable as={Segment} loading={loading}>
				<Sidebar
					as={Segment}
					animation="scale down"
					direction="right"
					visible={moreInfoIsVisible}
					inverted
					raised
				>
					<Button
						floated="right"
						size="tiny"
						icon="close"
						circular
						color="red"
						onClick={this._toggleMoreInfoSidebar}
					/>
					<Header as="h3" inverted>
						<Icon name="search" /> {t`more info`}
					</Header>
					{descriptions.map((description) => {
						if (candidate['description' + description.index])
							return (
								<div
									key={description.index}
									className="margin-bottom ui"
								>
									<Header as="h5" dividing inverted>
										{description.label}
									</Header>
									<p
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(
												candidate[
													'description' + description.index
												],
											),
										}}
									/>
								</div>
							);
						else return <section key={description.index} />;
					})}
				</Sidebar>
				<Sidebar.Pusher>
					<Grid>
						<GridColumn computer="10" tablet="8" mobile="16">
							{/*<LightBoxedImage
								{...selectedImg}
								freeHeight={true}
								forcedFormat={'medium'}
							/>*/}
							<InnerImageZoom
								src={selectedImg.mediumThumbUrl}
								zoomSrc={selectedImg.origUrl}
								fullscreenOnMobile={true}
							/>
							<Button
								color="red"
								className="more-info-btn mobile only"
								onClick={this._toggleMoreInfoSidebar}
							>
								<Icon name="search" /> {t`more info`}
							</Button>
							{/*</div>*/}
							<div className="padded mobile hidden">
								<Header as="h3">
									{candidate.name}
									<Header.Subheader>
										{candidate.Company.name}
									</Header.Subheader>
								</Header>
								{descriptions.map((description) => {
									if (candidate['description' + description.index])
										return (
											<div
												key={description.index}
												className="margin-bottom ui"
											>
												<Header as="h4">{description.label}</Header>
												<p
													dangerouslySetInnerHTML={{
														__html: DOMPurify.sanitize(
															candidate[
																'description' +
																	description.index
															],
														),
													}}
												/>
											</div>
										);
									else return <section key={description.index} />;
								})}
							</div>
						</GridColumn>

						<GridColumn
							computer="6"
							tablet="8"
							mobile="16"
							floated="right"
						>
							<div className={'padded'}>
								<Header as="h5" dividing>{t`Images`}</Header>
								<div className={'thumbContainer'}>
									{possibleImages
										.filter((image) => !!image)
										.map(({ smallThumbUrl }, index) => (
											<img
												key={smallThumbUrl.length * Math.random()}
												src={smallThumbUrl}
												className={
													selectedImageIndex === index
														? 'selected'
														: 'selectable'
												}
												onClick={() => {
													if (selectedImageIndex !== index) {
														this.setState({
															selectedImageIndex: index,
														});
													}
												}}
												alt={''}
											/>
										))}
								</div>
							</div>
							<div className="padded">
								<Header as="h3" className="mobile only">
									{candidate.name}
									<Header.Subheader>
										{candidate.Company.name}
									</Header.Subheader>
								</Header>
								<Header as="h5" dividing>{t`ratings`}</Header>
								<div className="text-centered">
									{ratingsLabel.map((label, index) => (
										<div key={label} className="padded-top">
											<Header as="h3">
												{label}
												{label === overallLabel && '*'}
											</Header>
											<Rating
												maxRating={10}
												icon="star"
												size={
													label === overallLabel ? 'huge' : 'large'
												}
												rating={ratings[index]}
												onRate={this._rate(index)}
												className={
													label === overallLabel ? '' : 'blue'
												}
											/>
										</div>
									))}
								</div>
								<div className={'vote-btn-container'}>
									<Button
										color="green"
										size="large"
										className="margin-top margin-bottom centered"
										disabled={!this._hasRatedEverything()}
										onClick={this._confirmRatings}
										labelPosition={'right'}
										icon={'chevron right'}
										content={t`vote next`}
									/>
								</div>
							</div>
						</GridColumn>
					</Grid>
				</Sidebar.Pusher>
			</Sidebar.Pushable>
		);
	}
}

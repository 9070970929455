import React from 'react';
import { Image, Menu } from 'semantic-ui-react';
import MenuList from './MenuList';
//import logo from '../static/imgs/logo.svg';
//import { config } from '../static/config';
import Award from './Award';
import { graphql } from 'react-apollo';
import {
	awardNotFoundHandler,
	queryErrorHandler,
} from '../utils/errorHandlers';
import { GenericSpinner } from './GenericSpinner';

const SidebarBlock = ({ data: { loading, error, award } }) => {
	if (loading) return <GenericSpinner />;
	if (error) return queryErrorHandler(error);

	if (!award) return awardNotFoundHandler();

	return (
		<div id="main-sidebar" className="tablet or lower hidden">
		
			<Menu vertical fluid size="massive">
				{award.logoMedia && award.logoMedia?.largeThumbUrl && (
					<div className="text-centered">
						<Image src={award.logoMedia.largeThumbUrl} id="logo" verticalAlign="top" />
					</div>
				)}
				<MenuList awardId={award.id} />
			</Menu>
		</div>
	);
};

export default graphql(Award.query, {
	options: (props) => ({
		variables: {
			id: props.awardId,
		},
		notifyOnNetworkStatusChange: true
	}),
})(SidebarBlock);

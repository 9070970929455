import React from 'react';
import { ACTIONS, generateUrl } from '../utils/urls';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import {
	awardNotFoundHandler,
	queryErrorHandler,
} from '../utils/errorHandlers';
import { GenericSpinner } from './GenericSpinner';
import { history } from '../utils/history';
import GridColumn from 'semantic-ui-react/dist/es/collections/Grid/GridColumn';
import {
    Grid,
	Button,
	Card,
	
	Icon,
	Image,
	
} from 'semantic-ui-react';
import GridRow from 'semantic-ui-react/dist/es/collections/Grid/GridRow';
import moment from 'moment';
import t from '../utils/labels';

const AwardsActive = gql`
    query {
        awards (filter: { statuses:[VOTING_OPEN,VOTING_CLOSED]}) {
            edges {
                node {
                    id,
                    status,
                    name,
                    edition,
                    year,
                    logoMedia {
                        mediumThumbUrl
                    },
                    votesCloseAt,
                    categories {
                        id
                        localId
                        color
                        name
                        description
                        special
                        candidacies {
                            id
                            localId
                            Company {
                                id
                            }
                        }
                        candidacyRatings {
                            id
                            localId
                        }
                        Media {
                            id
                            localId
                            origUrl
                            
                        }
                    }
                }
            }
        }
    }
`;

const AwardSelection = ({ data: { loading, error, awards } }) => {
	if (loading) return <GenericSpinner />;
	if (error) return queryErrorHandler(error);
	if (!awards) return awardNotFoundHandler();

    if (awards.edges.length === 0) {
        return awardNotFoundHandler();
    }

    if (awards.edges.length === 1) {
        history.push(generateUrl(
            'Award',
            ACTIONS.DETAIL,
            awards.edges[0].node.id,
        ));
        return '';
    }

	return (
		<Grid centered>
			<GridRow>
                <GridColumn tablet="8" mobile="16" computer="8" widescreen={4} textAlign='center'>
                    <h2>{t('award_selection_title')}</h2>
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn tablet="8" mobile="16" computer="8" widescreen={4}>
                    <Card.Group itemsPerRow={2} stackable={true}>
                        {awards.edges.map((awardNode) => {
                            const award = awardNode.node;

                            const expiration = moment(award.votesCloseAt);
                            const remainingDays = expiration.diff(moment(), 'days');
                        
                            const remainingCategories = award.categories
                                .filter((category) => !category.special)
                                .reduce(
                                    (sum, category) =>
                                        category.candidacies.length === category.candidacyRatings.length
                                            ? sum
                                            : sum + 1,
                                    0,
                                );

                            return (
                                <Card href={generateUrl(
                                    'Award',
                                    ACTIONS.DETAIL,
                                    award.id,
                                )} color='white'>
                                    {award.logoMedia && award.logoMedia?.mediumThumbUrl && (
                                        <Image src={award.logoMedia?.mediumThumbUrl} wrapped ui={true} />
                                    )}
                                    <Card.Content textAlign='center'>
                                        <Card.Header>{award.name} {award.year}</Card.Header>
                                        <Card.Meta>
                                            <div>{award.edition}</div>
                                        </Card.Meta>
                                        <Card.Description textAlign='center'>
                                            <div>
                                                {award.status === 'VOTING_OPEN' && <Icon name='star outline' />}
                                                {award.status === 'VOTING_CLOSED' && <Icon name='close' />}
                                                {' '+t('status_'+award.status)}
                                            </div>
                                        </Card.Description>
                                        <Card.Meta textAlign='center'>
                                            <br />
                                            {remainingDays >= 0 && (
                                                <span>
                                                    <Icon name='hourglass full' />
                                                    {remainingDays} {t`remaining days`}
                                                    &nbsp;&nbsp;&nbsp;
                                                </span>
                                            )}
                                            <span>
                                                <Icon name='tasks' />
                                                {remainingCategories}/
                                                {
                                                    award.categories.filter(
                                                        (c) => !c.special,
                                                    ).length
                                                }
                                            </span>
                                        </Card.Meta>
                                    </Card.Content>
                                    <Card.Content extra textAlign='center'>
                                        <div className='ui one buttons'>
                                            <Button color='green'>
                                                {(award.status === 'VOTING_OPEN' && remainingCategories > 0) ? t`vote_cta` : t`view_cta`}
                                            </Button>
                                        </div>
                                    </Card.Content>
                                </Card>
                            )
                        })}
                    </Card.Group>
                </GridColumn>
            </GridRow>
        </Grid>
	);
};

export default graphql(AwardsActive)(AwardSelection);

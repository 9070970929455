import React from 'react';
import { AuthContainer } from './AuthContainer';
import t from '../utils/labels';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

export const AuthError = () => (
	<AuthContainer>
		<div className="authError">
			<h2>{t`Wrong authentication title`}</h2>
			<h4>{t`Wrong authentication text`}</h4>
			<div className="big-link-container">
				<Link to="/login">{t`Return to login`} <Icon name='angle right' /></Link>
			</div>
		</div>
	</AuthContainer>
);

import React from 'react';
import { Statistic } from 'semantic-ui-react';
import t from '../utils/labels';

const RateStatistic = ({ label, value }) => {
	const statStr = value !== null ? value + '/10' : t`not voted`;

	return (
		<Statistic color="grey" size="tiny">
			<Statistic.Label>{label}:</Statistic.Label>
			<Statistic.Value>{statStr}</Statistic.Value>
		</Statistic>
	);
};

export default RateStatistic;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoggedRouter from './LoggedLayout';
import { AuthRoutes } from '@food/auth';
import { authConfig, authManager } from '../utils/auth';
import t from '../utils/labels';
import { AuthContainer } from './AuthContainer';
import { AuthError } from './AuthError';
import { NoAwardsError } from './NoAwardsError';
import { ConsumerWelcome } from './ConsumerWelcome';
import { ProducerWelcome } from './ProducerWelcome';
import { history } from '../utils/history';

const AppLayout = () => (
	<AuthRoutes
		config={authConfig}
		authManager={authManager}
		theme={{}}
		t={t}
		container={AuthContainer}
		onRedirect={(user,redirectUrl) => {
			const { location } = history;
			if (user && location.pathname === '/login') {
				history.push(redirectUrl);
			}
		}}
	>
		<Switch>
			<Route path={'/noawards'} component={NoAwardsError} />
			<Route path={'/autherror'} component={AuthError} />
			<Route path={'/consumer-welcome'} component={ConsumerWelcome} />
			<Route path={'/producer-welcome'} component={ProducerWelcome} />
			<Route path={["/:action?/:id?", "/:action?", "/"]} component={LoggedRouter} />
		</Switch>
	</AuthRoutes>
);

export default AppLayout;

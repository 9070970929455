import React from 'react';
import { Icon, MenuItem } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
//import { config } from '../static/config';
import t from '../utils/labels';
import { HTMLstriptags } from '../utils/misc';
import { graphql } from 'react-apollo';
import Award from './Award';
import { GenericSpinner } from './GenericSpinner';
import {
	awardNotFoundHandler,
	queryErrorHandler,
} from '../utils/errorHandlers';

const MenuList = ({ data: { loading, error, award } }) => {
	if (loading) return <GenericSpinner />;
	if (error) return queryErrorHandler(error);
	if (!award) return awardNotFoundHandler();

	return (
		<div id="menu-list">
			<MenuItem as={NavLink} exact to={"/awards/" + award.id}>
				<Icon name="home" />
				{t`home`}
			</MenuItem>

			{HTMLstriptags(award.descriptionForVoter) && (
				<MenuItem as={NavLink} exact to={"/awards/" + award.id + "/instructions"}>
					<Icon name="help" />
					{t`instructions`}
				</MenuItem>
			)}

			{HTMLstriptags(award.eventDescription) && (
				<MenuItem as={NavLink} exact to={"/awards/" + award.id + "/prize_gala"}>
					<Icon name="cocktail" />
					{t`prize gala`}
				</MenuItem>
			)}

			{HTMLstriptags(award.publicDescription) && (
				<MenuItem as={NavLink} exact to={"/awards/" + award.id + "/about_us"}>
					<Icon name="group" />
					{t`about us`}
				</MenuItem>
			)}
		</div>
	);
};

export default graphql(Award.query, {
	options: (props) => ({
		variables: {
			id: props.awardId,
		},
		notifyOnNetworkStatusChange: true
	}),
})(MenuList);

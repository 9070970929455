import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import t from '../utils/labels';

const GenericSpinner = () => (
	<Dimmer active page inverted>
		<Loader inverted>{t`Loading`}...</Loader>
	</Dimmer>
);

export { GenericSpinner };

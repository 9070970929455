import it_IT from '../static/i18n/it_IT';
import en_US from '../static/i18n/en_US';
import {config} from '../static/config';

const labels = {
	it_IT: it_IT,
	en_US: en_US,
};

const lang = config('LANG') || 'en_US';

const notTranslated = [];
const usedLabels = {};
function getNotTranslated () {
	return notTranslated.map(id => `'${id}': ''`).join(',\n');
}
function getUsedLabels () {
	return Object.keys(usedLabels).map(id => `'${id}': '${usedLabels[id] || id}'`).join(',\n');
}

if (typeof window !== 'undefined') {
	window.getNotTranslated = getNotTranslated;
	window.getUsedLabels = getUsedLabels;
}

function t(...stringIds) {
	let i = 0;
	for (i; i<stringIds.length; i++) {
		const stringId = stringIds[i];
		
		if (!stringId)
			continue;
		
		const id = typeof stringId === 'string' ? stringId : stringId[0];
		
		if (id in labels[lang]) {
			usedLabels[id] = labels[lang][id];
			return labels[lang][id];
		}
		
		// console.warn(`String "${id}" not translated!`);
		if (notTranslated.indexOf(id) === -1) {
			notTranslated.push(id);
			usedLabels[id] = false;
		}
		if (i === stringIds.length -1)
			return id;
	}
}

export default t;
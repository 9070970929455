import { auth
	//,	ROLES 
} from '@food/auth';
import { Channel } from '@food/sync';
import * as Sentry from '@sentry/browser';
import { parse } from 'query-string';
import { history } from './history';
import { client } from './client';
import { stacked } from './broadcastChannel';
import { authConfig } from './authConfig';
//import { config } from '../static/config';

const authChannel = new Channel('@food/auth');
const authManager = auth(authConfig);

const userChangedHandler = async (oldUser, newUser) => {
	const { location } = history;
	const searchParams = parse(location.search);
	const userIsEqual =
		(!oldUser && oldUser === newUser) ||
		(oldUser && newUser && oldUser.id === newUser.id);

	if (!userIsEqual) {
		await client.cache.reset();
		await client.resetStore();

		Sentry.configureScope((scope) => {
			if (newUser) {
				scope.setUser({
					id: newUser.id,
					username: newUser.name,
					email: newUser.email,
				});
			} else {
				scope.setUser(null);
			}
		});
	}

	if (!newUser || location.pathname === '/login') {
		const redirectUrl = searchParams.redirect || '/';
		history.push(redirectUrl);
	}
};

authChannel.onmessage = stacked((events) => {
	const lastEvent = events.filter((e) => e.data.type === 'USER_CHANGED')[0];
	if (lastEvent) {
		const { oldUser, newUser, isSwitch } = lastEvent.data.args;
		userChangedHandler(oldUser, newUser, isSwitch);
	}
}, 100);

export { authManager, authConfig, authChannel };

import React from 'react';
import { Grid, GridColumn, Icon, Segment, Statistic } from 'semantic-ui-react';
import moment from 'moment';
import t from '../utils/labels';
import { StandardWidget } from './StandardWidget';
import WidgetBlock from './WidgetBlock';

const PresentationalComponent = ({ widgets }) => (
	<GridColumn width={16}>
		{widgets.map((w) => (
			<StandardWidget key={w.id} widget={w} />
		))}
	</GridColumn>
);

const AwardWidgets = ({ award, isAwardDashboard }) => {
	const expiration = moment(award.votesCloseAt);
	const remainingDays = Math.max(
		Math.ceil(expiration.diff(moment(), 'days', true)),
		0,
	);
	const remainingCategories = award.categories
		.filter((category) => !category.special)
		.reduce(
			(sum, category) =>
				category.candidacies.length === category.candidacyRatings.length
					? sum
					: sum + 1,
			0,
		);
	return (
		<Grid>
			<GridColumn
				width={16}
				className={isAwardDashboard && 'tablet or lower hidden'}
			>
				<Segment className="text-centered">
					<Statistic>
						<Statistic.Label>
							<Icon name="hourglass full" /> {t`remaining days`}
						</Statistic.Label>
						<Statistic.Value>{remainingDays}</Statistic.Value>
					</Statistic>
				</Segment>
			</GridColumn>
			<GridColumn width={16} className={isAwardDashboard && 'tablet hidden'}>
				<Segment className="text-centered">
					<Statistic>
						<Statistic.Label>
							<Icon name="tasks" /> {t`remaining categories`}
						</Statistic.Label>
						<Statistic.Value>
							{remainingCategories}/
							{award.categories.filter((c) => !c.special).length}
						</Statistic.Value>
					</Statistic>
				</Segment>
			</GridColumn>
			{award.descriptionProducerHighlights && award.descriptionProducerHighlights !== "<p><br></p>" && award.categories.filter((v) => v.giftUrl).length &&
				<GridColumn width={16} className={isAwardDashboard && 'tablet hidden'}>
					<Segment className="text-centered">
						<div dangerouslySetInnerHTML={{__html: award.descriptionProducerHighlights}}></div>
					</Segment>
				</GridColumn>
			}
			<WidgetBlock
				filter={{
					locations: ['DASHBOARD'],
				}}
				PresentationalComponent={PresentationalComponent}
			/>
		</Grid>
		
	);
};

export { AwardWidgets };

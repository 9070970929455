import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';

class LightBoxedImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			loading: true,
		};
	}

	_toggle = () =>
		this.setState({
			open: !this.state.open,
		});

	componentWillReceiveProps(newProps) {
		if (newProps.smallThumbUrl !== this.props.smallThumbUrl) {
			this.setState({
				loading: true,
			});
		}
	}

	_imageLoaded = () =>
		this.setState({
			loading: false,
		});

	render() {
		const {
			smallThumbUrl,
			largeThumbUrl,
			// mediumThumbUrl,
			freeHeight,
			forcedFormat = 'small',
		} = this.props;
		const format = forcedFormat + 'ThumbUrl';
		const imgSrc = format in this.props ? this.props[format] : smallThumbUrl;
		return (
			<div className="fake-lightbox-container">
				<Modal
					basic
					size="small"
					open={this.state.open}
					onClose={this._toggle}
				>
					<Modal.Content>
						<div
							className="fake-lightbox"
							onClick={this._toggle}
							style={{ backgroundImage: `url("${largeThumbUrl}")` }}
						/>
					</Modal.Content>
				</Modal>
				<button
					onClick={(event) => {
						event.preventDefault();
						this._toggle();
					}}
					className={'fake-image-btn' + (freeHeight ? ' free-height' : '')}
				>
					<img
						onLoad={this._imageLoaded}
						className={this.state.loading ? 'hidden' : ''}
						src={imgSrc}
						alt={''}
						// srcSet={`${smallThumbUrl} 300w, ${mediumThumbUrl} 600w, ${largeThumbUrl} 1200w`}
					/>

					<div className="fake-image-cover" />
				</button>
			</div>
		);
	}
}

export { LightBoxedImage };

import React from 'react';
import { Redirect } from 'react-router-dom';
import { authManager } from './auth';
import * as Sentry from '@sentry/browser';
import t from '../utils/labels';
import { config } from '../static/config';

const queryErrorHandler = (error) => {
	// TODO find a better way to distinguish network errors and graphql errors
	if (
		error &&
		error.graphQLErrors &&
		error.graphQLErrors.length &&
		(error.graphQLErrors[0].status === 401 ||
			error.graphQLErrors[0].status === 404)
	) {
		return authErrorHandler(error);
	}

	console.error(error);
	sentryLog(error);
	return (
		<section>
			{t`server error introduction`}{' '}
			<a href={'mailto:' + config('HELP_EMAIL')}>{config('HELP_EMAIL')}</a>
		</section>
	);
};

const authErrorHandler = () => {
	authManager.logout();
	return <Redirect to={'/autherror'} />;
};

function sentryLog(ex, context) {
	Sentry.withScope((scope) => {
		scope.setExtra('context', context);
		Sentry.captureException(ex);
	});
}

function awardNotFoundHandler() {
	const e = new Error('Award not found');
	sentryLog(e);
	return <Redirect to={'/noawards'} />;
}

export { queryErrorHandler, sentryLog, awardNotFoundHandler };

import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import { config } from './static/config';
import { ApolloProvider } from 'react-apollo';
import * as Sentry from '@sentry/browser';
import * as ReactGA from 'react-ga';
import * as git from './static/rev.json';
import { injectCSS, defaultTheme } from '@food/css-manager';
import { isLogged, getUserData, AuthContext } from '@food/auth';
import { history } from './utils/history';
import { client } from './utils/client';
import '../node_modules/semantic-ui-css/semantic.min.css';
import '../node_modules/@food/auth/static/style.css';
import './index.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { init } from '@food/tracking';

if (config('UA_CODE')) {
	ReactGA.initialize(config('UA_CODE'));
}

init({
	baseUrl: config('API_ENDPOINT').TRACKING,
	rev: git.rev,
	bufferLength: 1,
	targetSite: config('TARGET_SITE'),
});

// catch errori con Sentry
if (config('SENTRY_SERVER')) {
	Sentry.init({ dsn: config('SENTRY_SERVER') });

	Sentry.configureScope((scope) => {
		scope.setTag('backend', config('HOST'));
		scope.setTag('commit', git.rev);
		if (isLogged()) {
			const user = getUserData();
			scope.setUser({
				id: user.id,
				username: user.name,
				email: user.email,
			});
		}
	});
}

injectCSS(defaultTheme);

ReactDOM.render(
	<Router history={history}>
		<AuthContext>
			<ApolloProvider client={client}>
				<AppLayout />
			</ApolloProvider>
		</AuthContext>
	</Router>,
	document.getElementById('root'),
);

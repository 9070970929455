import React, { Component } from 'react';
import {
	Button,
	Card,
	Container,
	Dimmer,
	Header,
	Icon,
	Image,
	Modal,
} from 'semantic-ui-react';
import t from '../utils/labels';
import { Link } from 'react-router-dom';
import GridColumn from 'semantic-ui-react/dist/es/collections/Grid/GridColumn';
import { ACTIONS, generateUrl } from '../utils/urls';
import { config } from '../static/config';

class AwardCategoryBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			detailOpen: false,
			disabledModalOpen: false,
		};
	}

	_toggleDetail = () => this.setState({ detailOpen: !this.state.detailOpen });

	render() {
		const { award, category, disabled } = this.props;
		const { disableModalOpen, detailOpen } = this.state;
		const unratedProductsNumber =
			category.candidacies.length - category.candidacyRatings.length;
		const closingDateIsPassed = new Date(award.votesCloseAt) < new Date();

		const reportMode =
			unratedProductsNumber === 0 ||
			(category.candidacyRatings.length > 0 &&
				(award.status === 'VOTING_CLOSED' || closingDateIsPassed));
		const emptyMode =
			category.candidacyRatings.length === 0 &&
			(award.status === 'VOTING_CLOSED' || closingDateIsPassed);

		let buttonText = 'continue';
		let buttonColor = 'green';
		let buttonIcon = 'search';
		let buttonLink;

		if (disabled) {
			buttonText = 'disabled';
			buttonColor = '';
			buttonIcon = 'ban';
			buttonLink = '';
		} else if (reportMode) {
			buttonText = 'report';
			buttonColor = 'red';
			buttonLink = [
				generateUrl('Award', ACTIONS.DETAIL, award.id),
				generateUrl('AwardCategory', ACTIONS.DETAIL, category.id),
				'/',
			].join('');
		} else {
			if (unratedProductsNumber === category.candidacies.length) {
				buttonText = 'start';
			} else {
				buttonText = 'continue';
			}
			buttonIcon = 'star';
			buttonLink = [
				generateUrl('Award', ACTIONS.DETAIL, award.id),
				generateUrl('AwardCategory', ACTIONS.DETAIL, category.id),
				'/ratings',
			].join('');
		}

		return (
			<GridColumn tablet="8" mobile="16" computer="8" widescreen={5}>
				<Dimmer
					active={detailOpen}
					onClickOutside={this._toggleDetail}
					page
				>
					<Container>
						<Header as="h2" icon inverted>
							<Header.Subheader>{category.description}</Header.Subheader>
						</Header>
						<Button
							className="a-bit-more-right"
							basic
							icon
							onClick={this._toggleDetail}
						>
							<Icon inverted name="close" size="big" />
						</Button>
					</Container>
				</Dimmer>
				<Card fluid className="margin-bottom">
					<Card.Content>
						{category.Media && (
							<div className={'logo-image'}>
								<Image src={category.Media.origUrl} />
							</div>
						)}
						<Card.Header>{category.name}</Card.Header>
					</Card.Content>

					<Card.Content extra>
						{unratedProductsNumber === 0 && <Icon name="star" />}
						{unratedProductsNumber > 0 &&
							category.candidacyRatings.length > 0 && (
								<Icon name="star half empty" />
							)}
						{category.candidacyRatings.length === 0 &&
							unratedProductsNumber > 0 && <Icon name="star empty" />}
						{category.candidacyRatings.length}/
						{category.candidacies.length} Voted
						{reportMode && category.giftUrl && (
							<Button positive floated="right" onClick={(e) => {window.open(category.giftUrl, "_blank");}}>
								{t`PDF`}
							</Button>
						)}
						{(!category.giftUrl || !reportMode) && !config('HIDE_INFO') && (
							<Button floated="right" onClick={this._toggleDetail}>
								{t`info`}
							</Button>
						)}
						<Modal
							open={disableModalOpen}
							onClose={() => this.setState({ disableModalOpen: false })}
							size={'tiny'}
						>
							<Modal.Header>{t`category vote forbidden title`}</Modal.Header>
							<Modal.Content>
								<Modal.Description>
									<p
										dangerouslySetInnerHTML={{
											__html: t`category vote forbidden html`,
										}}
									/>
								</Modal.Description>
							</Modal.Content>
							<Modal.Actions>
								<Button
									positive
									icon="angle right"
									labelPosition="right"
									content={t`Proceed`}
									onClick={() =>
										this.setState({ disableModalOpen: false })
									}
								/>
							</Modal.Actions>
						</Modal>
						{!emptyMode && (
							<Button
								floated="right"
								color={buttonColor}
								labelPosition="left"
								as={Link}
								basic={reportMode}
								onClick={(e) => {
									if (disabled) {
										e.preventDefault();
										this.setState({ disableModalOpen: true });
									}
								}}
								to={buttonLink}
								content={t(buttonText)}
								icon={buttonIcon}
							/>
						)}
					</Card.Content>
				</Card>
			</GridColumn>
		);
	}
}

export default AwardCategoryBlock;

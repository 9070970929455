export default {
	Menu: 'Menu',
	home: 'Home',
	overall: 'Sales potential',
	close: 'Chiudi',
	'options.email error': 'user/password errata',
	'options.password error': 'user/password errata',
	instructions: 'Istruzioni',
	'prize gala': 'Premiazione',
	'about us': 'Il premio',
	Logout: 'Logout',
	Loading: 'Caricamento',
	Copyright: 'Copyright',
	'Food footer': 'Food S.r.l.',
	'remaining days': 'Giorni Rimasti per Votare',
	'remaining categories': 'Categorie non ancora votate',
	'browse the magazine': 'Consulta la rivista',
	info: 'Info',
	report: 'Resoconto',
	start: 'Inizia',
	'few products remaining': 'Manca Poco!',
	'few products remaining description':
		'Ti restano pochi prodotti per completare la categoria e ottenere un resoconto dei tuoi giudizi',
	'stay on this category': 'Resta qui',
	'leave page': 'Lascia la categoria',
	'more info': 'Più Informazioni',
	'launch date': 'Data di Lancio sul Mercato',
	ratings: 'Valutazioni',
	'vote next': 'Vota il Prossimo',
	'shelf life': 'Shelf life',
	regulation: 'Termini e Condizioni',
	'thanks for your ratings!': 'Grazie per le tue valutazioni!',
	'now go elsewhere': 'Guarda il Resoconto dei tuoi Voti',
	'return dashboard': 'Torna alla Home',
	'see results': 'Vedi i Risultati',
	email: 'email',
	password: 'password',
	login: 'Login',
	'contact intro': 'Se hai problemi ad entrare per favore contatta',
	'producers not allowed': 'Area Riservata',
	'producers not allowed text':
		"Ci spiace ma l'area di voto è riservata ai Buyers della GDO",
	'not voted': '',
	'USER_NOT_FOUND/Error': 'Errore di autenticazione',
	'USER_NOT_FOUND/HelpText':
		'Controlla di aver inserito correttamente i dati di accesso',
	'wrong award status/Error': 'Manifestazione non accessibile',
	'wrong award status/HelpText':
		'Non è attualmente possibile esprimere un voto su questa manifestazione',
	continue: 'Continua',
	'category vote forbidden title': 'Accesso non consentito',
	'category vote forbidden html':
		"Non è possibile esprimere il voto su questa categoria, in quanto ci sono prodotti candidati dell'azienda a lei associata.",
	Proceed: 'Continua',
	disabled: 'Bloccato',
	'return to home': 'Torna alla home',
	'Waiting review explanation html':
		'<p>Il tuo utente è stato creato e la mail verificata.</p><p>Per garantire pieno accesso alla piattaforma è comunque necessario attendere la creazione del profilo aziendale da parte nostra. Verrai notificato di avvenuta creazione o rifiuto tramite email al termine del processo.</p>',
	'Waiting Review': 'Email verificata',
	'alreadyUsedEmail error': 'Indirizzo email già in uso',
	'grantToken error': 'Errore di accesso ai dati Social',
	'... or logout': '... o fai logout',
	'Renew your authentication': 'Rinnova la tua autenticazione',
	'Use the credentials we gave you':
		'Utilizza le credenziali che ti abbiamo fornito',
	'Sign In / Register': 'Log In / Registrati',
	'Sign In with your social account': 'Accedi con i tuoi account social',
	'Sign In with your social accounts': 'Accedi con i tuoi account social',
	'Sign Up or Log In with your social accounts':
		'Accedi o registrati con un account social',
	'Sign In with username and password': 'Accedi con username e password',
	'GOOGLE login button': 'Accedi con Google',
	'Sign in with GOOGLE': 'Accedi con Google',
	'FACEBOOK login button': 'Accedi con Facebook',
	'Sign in with FACEBOOK': 'Accedi con Facebook',
	'LINKEDIN login button': 'Accedi con Linkedin',
	'Sign in with LINKEDIN': 'Accedi con Linkedin',
	'Registration - Terms & Conditions': 'Registrazione / Termini & Condizioni',
	'To continue, you must agree to Gruppo Food Terms of Service and Privacy Policy':
		'Per continuare devi accettare la Privacy Policy e Termini & Condizioni di Gruppo Food',
	'Click here to read them in English': 'Clicca per leggerli in inglese',
	'I Agree': 'Accetto',
	'Registration - Business Profile': 'Registrazione / Profilo business',
	'We reserve specific functionalities to suppliers, buyers and food service operators so we have to be sure you work in the food business.':
		'Riserviamo alcune funzionalità specifiche a produttori, buyer e operatori del settore food service; per questo motivo dobbiamo accertare la tua appartenenza al settore',
	"We can't accept public email domains (@gmail.com, @aol.com, ...)":
		'Non verranno accettati domini email pubblici (@gmail.com, @aol.com, ...)',
	Continue: 'Continua',
	'We sent you a verification email to':
		"Ti abbiamo inviati una mail all'indirizzo",
	'Please click the link in it to complete the registration':
		'Clicca il link contenuto al suo interno per terminare la registrazione',
	'Change work email': 'Cambia indirizzo email',
	loading: 'Caricamento...',
	Hello: 'Benvenuto',
	'Thanks for validating': "Grazie per aver effettuato l'accesso",
	'You have been successfully registered as a': 'Sei stato registrato come',
	CONSUMER: 'Consumatore',
	'as we related you to': 'e sei stato associato a',
	"If you think we're wrong please": 'Se pensi si sia verificato un problema',
	'contact us': 'contattaci',
	'Insert your job email address':
		'Inserisci il tuo indirizzo email di lavoro',
	'Registration intro':
		"Le votazioni sono riservate ai professionisti del Retail e dell'Industria. Il nostro staff verificherà il tuo indirizzo email aziendale prima di garantirti la possibilità di votare. Seleziona il profilo adatto al tuo utente:",
	Registration: 'Registrazione',
	'Role BUYER': 'Lavoro per un Retailer alimentare non italiano',
	'Role BUYER_PL_ITALIA': 'Lavoro per un Retailer alimentare italiano',
	'Role PRODUCER': "Sono un operatore dell'Industria Food & Beverage",
	Back: 'Indietro',
	"If your company doesn't have a private email domain":
		'Se la tua azienda non possieme un dominio email privato',
	'consumerOnlyEmail error':
		"Questo dominio email è di un provider pubblico; non possiamo quindi dedurre e garantire la tua appartenenza ad un'azienda del settore. Per favore usa un dominio di proprietà aziendale oppure contattaci",
	'Wrong authentication title': 'Autenticazione fallita',
	'Wrong authentication text':
		"Non è stato possibile completare l'autenticazione. Forse stai cercando di accedere a un evento concluso, oppure non hai i permessi per accedere a questo evento.",
	'Return to login': 'Torna al login',
	Warning: 'Attenzione',
	'The activation link is expired.':
		'Il link di attivazione utilizzato è scaduto e quindi non più valido. Esegui nuovamente il percorso di registrazione per ottenere un nuovo link',
	'Back to login': 'Torna al login',
	'Maybe later': 'Ricordamelo più tardi',
	'Switch to Social Account': 'Collega un account Social',
	'switch to social explanation title':
		'Stiamo dismettendo gli accessi tramite password',
	'switch to social explanation html': `Collega un tuo account social e d'ora potrai accedere con un click senza dover tenere a mente le credenziali.`,
	'Associate a social account to your user':
		'Scegli un account social da associare',
	// --------------- -- -- ---
	BUYER_PL_ITALIA: 'Retailer alimentare italiano',
	'server error introduction':
		'Errore server: per cortesia riprovare fra qualche minuto. Se il problema persiste contattare',
	'Insert the password to renew as':
		'Inserisci la password per accedere nuovamente come',
	Renew: 'Accedi',
	'... or Logout': '... o fai logout',
	'Sign In': 'Accedi',
	'Authentication error': 'Errore di autenticazione',
	'If the problem persists': 'Se il problema persiste',
	retry: 'Riprova',
	'Contact us': 'Contattaci',
	'voting error': 'Errore tecnico',
	'voting error description html':
		" Si è verificato un errore nel salvataggio del tuo voto.<br />Per cortesia riprova fra poco e, se l'errore dovesse ripresentarsi, contattaci.",
	Images: 'Immagini',
	'remember social account html':
		'Ricorda che puoi accedere senza digitare email e password utilizzando il tuo account social.',
	'social login type associated': 'Account social collegato',
	proceed: 'Continua',
	'remember switch to social title': 'Account Social collegato',
	'No awards title': 'Non ci sono manifestazioni aperte al momento',
	'No awards text': 'Stiamo preparando le prossime manifestazioni, torna nei prossimi giorni!',
	'status_VOTING_OPEN': 'Votazioni aperte',
	'status_VOTING_CLOSED': 'Votazioni chiuse',
	'award_selection_title': 'Seleziona una Manifestazione',
	'vote_cta': 'Vota',
	'view_cta': 'Visualizza'
};

/*
"'Sign In': '',
'Authentication error': '',
'If the problem persists': ''"
 */

//import { ACTIONS, generateUrl } from '../utils/urls';
import { ROLES } from '@food/auth';

const baseUrlProd = process.env.REACT_APP_BASE_URL_PROD;
const baseUrlDev = process.env.REACT_APP_BASE_URL_DEV;

export const CONFIG = {
	[process.env.REACT_APP_PROD_PRODOTO_URL]: {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-21094558-9',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		LOGO: '/imgs/prodotto-food-generic.png',
		LANG: 'it_IT',
		HELP_EMAIL: 'prodottofood@gruppofood.com',
		AUTH_ROLES: ['BUYER_PL_ITALIA', 'PRODUCER'],
	},
	[process.env.REACT_APP_STAGE_AWARDS_URL]: {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		AUTH_ROLES: [ROLES.BUYER],
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/italianfood-generic.svg',
		LANG: 'en_US',
	},
	[process.env.REACT_APP_PROD_VOTE_URL]: {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		AUTH_ROLES: [ROLES.BUYER],
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/fausa2023.jpg',
		LANG: 'en_US',
	},
	[process.env.REACT_APP_STAGE_VOTE_URL]: {
		HOST: baseUrlDev,
		GRAPHQL_API: baseUrlDev + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlDev + '/api/authenticate',
			VERIFY: baseUrlDev + '/api/verify',
			TRACKING: baseUrlDev + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		AUTH_ROLES: [ROLES.BUYER, ROLES.PRODUCER],
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/prodotto-food-generic.png',
		LANG: 'en_US',
		GOOGLE_AUTH_URL:
		'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&response_type=code&client_id=686200956517-ekcd649hqj7nuf6q19grqp9ofqlvnqu4.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/plus.login+https://www.googleapis.com/auth/userinfo.profile',
	},
	[process.env.REACT_APP_PROD_DOLCE_URL]: {
		HOST: baseUrlProd,
		GRAPHQL_API: baseUrlProd + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlProd + '/api/authenticate',
			VERIFY: baseUrlProd + '/api/verify',
			TRACKING: baseUrlProd + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@gruppofood.com',
		REQUIRES_AUTH: true,
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/dolcesalato-generic.svg',
		LANG: 'it_IT',
		AUTH_ROLES: [],
	},
	'localhost': {
		HOST: baseUrlDev,
		GRAPHQL_API: baseUrlDev + '/graphql',
		API_ENDPOINT: {
			AUTH: baseUrlDev + '/api/authenticate',
			VERIFY: baseUrlDev + '/api/verify',
			TRACKING: baseUrlDev + '/api/bucket/tracking',
		},
		TOP_RATINGS_SHOWED: 50,
		UA_CODE: 'UA-50942936-4',
		SENTRY_SERVER:
			'https://c25a48eb1e934c889d1dc9077b08b0c3@sentry.io/215153',
		TARGET_SITE: 'VOTE',
		HELP_EMAIL: 'support@italianfood.net',
		REQUIRES_AUTH: true,
		LOGIN_START_TRADITIONAL: true,
		LOGO: '/imgs/prodotto-food-generic.png',
		LANG: 'en_US',
		AUTH_ROLES: [],
	},
};

export function config(key) {
	return CONFIG[window.location.hostname][key];
}

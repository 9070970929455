import React from 'react';
import { AuthContainer } from './AuthContainer';
import t from '../utils/labels';
//import { Link } from 'react-router-dom';
//import { Icon } from 'semantic-ui-react';
import { Button, ButtonLayouts } from '@food/ui';
import { authManager } from '../utils/auth';

export const NoAwardsError = () => (
	<AuthContainer>
		<div className="authError">
			<h2>{t`No awards title`}</h2>
			<h4>{t`No awards text`}</h4>

			<p>
				<Button
					label={t`Logout`}
					layout={ButtonLayouts.BUTTON}
					onClick={() => authManager.logout()}
				/>
			</p>
		</div>
	</AuthContainer>
);

import { ApolloLink } from 'apollo-link';
import { RenewLink, targetSiteLink } from '@food/auth';
import { HttpLink } from 'apollo-link-http';
import { config } from '../static/config';
import {
	InMemoryCache,
	IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { authManager } from './auth';
import { authConfig } from './authConfig';
import introspectionQueryResultData from '../static/fragmentTypes.json';
import 'isomorphic-fetch';
import * as Sentry from '@sentry/browser';

const maxRepeat = 10;
const sleep = (seconds) =>
	new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const retryFetch = async (url, options) => {
	let repeatCount = 0;
	while (repeatCount++ < maxRepeat) {
		try {
			const response = await fetch(url, options);
			if (response.status !== 502 || repeatCount === maxRepeat) {
				if (repeatCount === maxRepeat) {
					/*track({
						event: 'max fetch repeat count reached - server error',
						url,
						options,
						response,
					});*/
					Sentry.withScope((scope) => {
						scope.setExtra('data', {
							url,
							options,
							response,
						});
						Sentry.captureException(
							new Error('Max fetch repeat count reached - server error'),
						);
					});
				}

				return response;
			} else {
				await sleep(3);
			}
		} catch (e) {
			if (repeatCount === maxRepeat) {
				/*track({
					event: 'max fetch repeat count reached - network error',
					url,
					options,
					error: e,
				});*/
				Sentry.withScope((scope) => {
					scope.setExtra('data', {
						url,
						options,
						error: e,
					});
					Sentry.captureException(
						new Error('Max fetch repeat count reached - network error'),
					);
				});
			} else {
				await sleep(3);
			}
		}
	}
};

const link = ApolloLink.from([
	// new waitLink(),
	new (targetSiteLink(authConfig))(),
	new (RenewLink(authManager))(),
	new HttpLink({
		uri: config('GRAPHQL_API'),
		fetchOptions: {
			'no-cors': true,
			method: 'POST',
		},
		fetch: retryFetch,
	}), // link standard per richieste graphql tramite http
]);

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
});

const cache = new InMemoryCache({
	fragmentMatcher,
	dataIdFromObject: (object) => object.id,
});

const client = new ApolloClient({
	link,
	cache,
});

export { client };

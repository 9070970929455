import React from 'react';
import { getUserData, ROLES, StepContainer, UserData } from '@food/auth';
import { Button, ButtonLayouts } from '@food/ui';
import { Redirect } from 'react-router-dom';
import t from '../utils/labels';
import { currySC, registerClass } from '@food/css-manager';
import { authManager } from '../utils/auth';
import { config } from '../static/config';
import { AuthContainer } from './AuthContainer';

const styleClass = registerClass(
	(t, sc) => `
	
	font-size: ${t.ratios.l}rem;
	
	.${sc('main-text')} {
		margin-bottom: 1rem;
	}
	
	ul {
		list-style-type: none;
		padding: 0;
	}
	
	.${sc('upgrade-btn')} {
		text-decoration: none;
	}
	
	.${sc('fake-link')} {
		text-decoration: underline;
		color: ${t.colors.grey.plain};
	}
	`,
);

const sc = currySC(styleClass);

export const ConsumerWelcome = () => {
	const user = getUserData();

	if (!user || user.role !== ROLES.CONSUMER) {
		return <Redirect to={'/'} />;
	}

	return (
		<UserData>
			{({ userData, loading }) => {
				if (loading || !userData) {
					return null;
				}

				return (
					<AuthContainer>
						<StepContainer>
							<h2>{t`consumer welcome title`}</h2>
							<section className={styleClass}>
								<p>
									<div
										className={sc('main-text')}
										dangerouslySetInnerHTML={{
											__html: t`consumer welcome html 1`,
										}}
									/>
									<div className={'text-center'}>
										<Button
											className={sc('upgrade-btn')}
											type={'success'}
											label={t`Upgrade to Buyer`}
											layout={ButtonLayouts.BUTTON}
											linkTo={'/login?upgraderole=BUYER'}
										/>
									</div>
								</p>
								<p>
									{t`if you're not`} <strong>{userData.name}</strong>{' '}
									<Button
										label={t`change account`}
										layout={ButtonLayouts.LINK}
										className={sc('fake-link')}
										onClick={() => authManager.logout()}
									/>
									.
								</p>
								<p>
									{t`if you think there's a problem with your account`}{' '}
									<a
										href={'mailto:' + config('HELP_EMAIL')}
									>{t`contact us`}</a>
									.
								</p>
							</section>
						</StepContainer>
					</AuthContainer>
				);
			}}
		</UserData>
	);
};

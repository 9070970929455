import React from 'react';
import { StepContainer, UserData } from '@food/auth';
import { Button } from '@food/ui';
import t from '../utils/labels';
import { currySC, registerClass } from '@food/css-manager';
import { authManager } from '../utils/auth';
import { config } from '../static/config';
import { AuthContainer } from './AuthContainer';

const styleClass = registerClass(
	(t, sc) => `
	font-size: ${t.ratios.l}rem;
	
	ul {
		list-style-type: none;
		padding: 0;
	}
	
	.${sc('fake-link')} {
		text-decoration: underline;
		color: ${t.colors.grey.plain};
	}

	`,
);

const sc = currySC(styleClass);

export const ProducerWelcome = () => (
	<UserData>
		{({ userData, loading }) => {
			if (loading || !userData) {
				return null;
			}

			return (
				<AuthContainer>
					<StepContainer>
						<h2>{t`producer welcome title`}</h2>
						<section className={styleClass}>
							<p>
								<div
									className={sc('main-text')}
									dangerouslySetInnerHTML={{
										__html: t`producer welcome html`,
									}}
								/>
							</p>
							<p>
								{t`producer wrong role text`}{' '}
								<a
									href={'mailto:' + config('HELP_EMAIL')}
								>{t`contact us`}</a>
								.
							</p>
							<p>
								<Button
									label={t`Logout`}
									onClick={() => authManager.logout()}
								/>
							</p>
						</section>
					</StepContainer>
				</AuthContainer>
			);
		}}
	</UserData>
);

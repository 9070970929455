import React from 'react';
import { Card } from 'semantic-ui-react';

export const StandardWidget = ({
	widget: { bodyRendered, titleRendered, footerRendered },
}) => (
	<Card fluid={true}>
		<Card.Content>
			{titleRendered && (
				<Card.Header>
					<span dangerouslySetInnerHTML={{ __html: titleRendered }} />
				</Card.Header>
			)}

			<Card.Description>
				<div dangerouslySetInnerHTML={{ __html: bodyRendered }} />
			</Card.Description>

			{footerRendered && (
				<Card.Content extra>
					<span dangerouslySetInnerHTML={{ __html: footerRendered }} />
				</Card.Content>
			)}
		</Card.Content>
	</Card>
);

import React from 'react';
import {
	Grid,
	GridColumn,
	Header,
	Icon,
	Image,
	Segment,
	Statistic,
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import AwardCategoryBlock from './AwardCategoryBlock';
import GridRow from 'semantic-ui-react/dist/es/collections/Grid/GridRow';
import {
	awardNotFoundHandler,
	queryErrorHandler,
} from '../utils/errorHandlers';
import t from '../utils/labels';
//import { config } from '../static/config';
import moment from 'moment';
import { AwardWidgets } from './AwardWidgets';
import { GenericSpinner } from './GenericSpinner';
//import img from '../static/imgs/logo.svg';
import { UserData } from '@food/auth';

// query
const AwardQuery = gql`
	query Award($id: ID!) {
		award: node(id: $id) {
			id
			... on Award {
				localId
				name
				year
				edition
				status
				descriptionForVoter
				descriptionProducerHighlights
				eventDescription
				publicDescription
				ratingOverallLabel
				logoMedia {
					id
					localId
					largeThumbUrl
				}
				votesCloseAt
				categories {
					id
					localId
					color
					name
					description
					special
					giftUrl
					candidacies {
						id
						localId
						Company {
							id
						}
					}
					candidacyRatings {
						id
						localId
					}
					Media {
						id
						localId
						origUrl
						
					}
				}
			}
		}
	}
`;

// component
const Award = ({ data: { loading, award, error } }) => {
	if (loading) return <GenericSpinner />;
	if (error) return queryErrorHandler(error);
	if (!award) return awardNotFoundHandler();

	console.log(award);

	const expiration = moment(award.votesCloseAt);
	const remainingDays = expiration.diff(moment(), 'days');

	// shuffle categories
	award.categories = award.categories.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);

	const remainingCategories = award.categories
		.filter((category) => !category.special)
		.reduce(
			(sum, category) =>
				category.candidacies.length === category.candidacyRatings.length
					? sum
					: sum + 1,
			0,
		);

	

	return (
		<section data-role="Award">
			<Segment className="computer large screen widescreen hidden" padded>
				<Header as="h2" size="large">
					{award.logoMedia && award.logoMedia?.largeThumbUrl && (
						<Image src={award.logoMedia.largeThumbUrl} />
					)}
					<Header.Content>
						{award.name}
						<Header.Subheader>{award.edition}</Header.Subheader>
					</Header.Content>
				</Header>
			</Segment>
			<Grid>
				<GridRow>
					<GridColumn computer="12" tablet="16" mobile="16">
						<Grid>
							<GridRow className="mobile tablet only">
								<GridColumn mobile="16" computer="8" tablet="8">
									<Segment className="text-centered">
										<Statistic>
											<Statistic.Label>
												<Icon name="hourglass full" />{' '}
												{t`remaining days`}
											</Statistic.Label>
											<Statistic.Value>
												{remainingDays}
											</Statistic.Value>
										</Statistic>
									</Segment>
								</GridColumn>
								<GridColumn
									computer="8"
									tablet="8"
									className="tablet only"
								>
									<Segment className="text-centered">
										<Statistic>
											<Statistic.Label>
												<Icon name="tasks" />{' '}
												{t`remaining categories`}
											</Statistic.Label>
											<Statistic.Value>
												{remainingCategories}/
												{
													award.categories.filter(
														(c) => !c.special,
													).length
												}
											</Statistic.Value>
										</Statistic>
									</Segment>
								</GridColumn>
							</GridRow>
							<GridRow>
								<UserData>
									{(userData) => {
										const hasUsercompany =
											userData.managedCompany &&
											userData.managedCompany.Company
												? (category) =>
														category.candidacies
															.map((c) => c.Company.id)
															.indexOf(
																userData.managedCompany.Company
																	.id,
															) !== -1
												: () => false;

										return (
											award.categories &&
											award.categories
												.filter((category) => !category.special)
												.map((category) => (
													<AwardCategoryBlock
														award={award}
														category={category}
														key={category.localId}
														disabled={hasUsercompany(category)}
													/>
												))
										);
									}}
								</UserData>
							</GridRow>
						</Grid>
					</GridColumn>
					<GridColumn computer="4" tablet="16" mobile="16">
						<AwardWidgets award={award} isAwardDashboard={true} />
					</GridColumn>
				</GridRow>
			</Grid>
		</section>
	);
};

// TODO rimuovere questa option strana una volta che il bug di apollo è risolto
// https://github.com/apollographql/apollo-client/issues/1186
export default {
	query: AwardQuery,
	component: graphql(AwardQuery, (props) => ({
		variables: { id: props.id },
		notifyOnNetworkStatusChange: true,
	}))(Award),
};

//import gql from 'graphql-tag';
//import { client } from './client';
//import { config } from '../static/config';

const getFirstBy = (array, key, value) => {
	let i = 0;
	let obj;
	while (i < array.length) {
		obj = array[i];
		if (obj[key] === value) return obj;
		i++;
	}
	return null;
};

const useTitle = function(title) {
	/*
	useEffect(() => {
		const prevTitle = document.title
		document.title = title
		return () => {
			document.title = prevTitle
		}
	})
	*/
}

export { getFirstBy, useTitle };

import React from 'react';
import {
	Grid,
	GridColumn,
	GridRow,
	Header,
	Segment,
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import Award from './Award';
import { AwardWidgets } from './AwardWidgets';
import {
	awardNotFoundHandler,
	queryErrorHandler,
} from '../utils/errorHandlers';
import t from '../utils/labels';
import { GenericSpinner } from './GenericSpinner';
//import { config } from '../static/config';

const PrizeGala = ({ data: { loading, error, award } }) => {
	if (loading) return <GenericSpinner />;
	if (error) return queryErrorHandler(error);
	if (!award) return awardNotFoundHandler();

	return (
		<div>
			<Segment padded>
				<Header as="h2" size="large">
					<Header.Content>{t`prize gala`}</Header.Content>
				</Header>
			</Segment>
			<Grid>
				<GridRow>
					<GridColumn computer="12" tablet="16" mobile="16">
						<Segment>
							<div
								dangerouslySetInnerHTML={{
									__html: award.eventDescription,
								}}
							/>
						</Segment>
					</GridColumn>
					<GridColumn computer="4" tablet="16" mobile="16">
						<AwardWidgets award={award} />
					</GridColumn>
				</GridRow>
			</Grid>
		</div>
	);
};

export default {
	query: Award.query,
	component: graphql(Award.query, (props) => ({
		variables: { id: props.id },
		notifyOnNetworkStatusChange: true,
	}))(PrizeGala),
};
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
//import { config } from '../static/config';
import {
	Button,
	Grid,
	GridColumn,
	Header,
	Icon,
	Menu,
	MenuItem,
	Sidebar,
	SidebarPushable,
	SidebarPusher,
} from 'semantic-ui-react';
import SidebarBlock from './Sidebar';
import Topbar from './Topbar';
import { withRouter } from 'react-router-dom';
import t from '../utils/labels';

import Award from './Award';
import AwardCategoryRateView from './AwardCategoryRateView';
import AwardCategoryCompletedView from './AwardCategoryCompletedView';
import Footer from './Footer';
import MenuList from './MenuList';
import AboutUs from './AboutUs';
import AwardSelection from './AwardSelection';
import Instructions from './Instructions';
import PrizeGala from './PrizeGala';
import { 
	//getUserData,
	 UserData
	//,	 ROLES
	 } from '@food/auth';
import { GenericSpinner } from './GenericSpinner';

class LoggedRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			awardId: this.props.match.params.id ? this.props.match.params.id : false,
			sidebarOpen: false,
			pathname: this.props.location.pathname,
			isRating: false
		};
	}

	componentDidMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			if (['REPLACE', 'PUSH'].indexOf(action) !== -1)
				this.setState({
					awardId: this.props.match.params.id ? this.props.match.params.id : false,
					sidebarOpen: false
				});
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	componentWillMount() {
		this.setState({
			awardId: this.props.match.params.id ? this.props.match.params.id : false, 
			isRating: this.props.location.pathname.indexOf('/ratings') > -1
		});
		console.log("will",this.state);
	}

	_toggleSidebar = () =>
		this.setState({ sidebarOpen: !this.state.sidebarOpen });

	render() {
		/*this.setState({
			awardId: this.props.match.params.id ? this.props.match.params.id : false, 
			isRating: this.props.location.pathname.indexOf('/ratings') > -1
		});

		this.state = {
			...this.state,
			awardId: this.props.match.params.id ? this.props.match.params.id : false,
			isRating: this.props.location.pathname.indexOf('/ratings') > -1
		};*/

		console.log(this.state);

		return (
			<UserData>
				{(all) => {
					const { isLogged, loading } = all;
					if (loading) {
						return <GenericSpinner />;
					}

					if (!isLogged) {
						return <Redirect to={'/login'} />;
					}

					//const user = getUserData();

					/*if (user.role === ROLES.CONSUMER && user.status === 'ACTIVE') {
						return <Redirect to={'/consumer-welcome'} />;
					}

					if (
						user.role === ROLES.PRODUCER &&
						user.status === 'ACTIVE' &&
						config('AUTH_ROLES').indexOf('PRODUCER') === -1
					) {
						return <Redirect to={'/producer-welcome'} />;
					}*/

					return (
						<section>
							<SidebarPushable>
								{ this.state.awardId &&
									<Sidebar
										as={Menu}
										vertical
										inverted
										animation="overlay"
										visible={this.state.sidebarOpen}
									>
										<MenuItem>
											<Button
												floated="right"
												compact
												onClick={this._toggleSidebar}
												basic
												icon
											>
												<Icon inverted name="arrow left" size="big" />
											</Button>
											<Header as="h2" inverted>
												{t`Menu`}
											</Header>
										</MenuItem>
										<MenuList awardId={this.state.awardId} toggler={this._toggleSidebar} />
									</Sidebar>
								}
								<SidebarPusher dimmed={this.state.sidebarOpen}>
									<Topbar showOnDesktop={this.state.isRating} toggler={this.state.awardId ? this._toggleSidebar : false} />
									<div id="main-wrapper">
										<div id="footer-wrapper">
											<main id="main-content">
												<Grid padded>
													<GridColumn
														computer="16"
														tablet="16"
														mobile="16"
													>
														<Switch>
															<Route
																path="/award-selection"
																exact
																component={AwardSelection}
															/>
															<Route
																path="/awards/:id"
																exact
																component={(props) => (
																	<Award.component
																		id={decodeURIComponent(
																			props.match.params.id,
																		)}
																	/>
																)}
															/>
															<Route
																path="/awards/:id/awardcategories/:cat_id/ratings"
																exact
																component={(props) => (
																	<AwardCategoryRateView
																		awardId={decodeURIComponent(
																			props.match.params.id,
																		)}
																		categoryId={decodeURIComponent(
																			props.match.params
																				.cat_id,
																		)}
																	/>
																)}
															/>
															<Route
																path="/awards/:id/awardcategories/:cat_id"
																exact
																component={(props) => (
																	<AwardCategoryCompletedView
																		awardId={decodeURIComponent(
																			props.match.params.id,
																		)}
																		categoryId={decodeURIComponent(
																			props.match.params
																				.cat_id,
																		)}
																	/>
																)}
															/>
															<Route
																path="/awards/:id/awardcategories/:cat_id/ratings/:candidacy_id"
																exact
																component={({
																	match: { params },
																}) => (
																	<AwardCategoryRateView
																		awardId={decodeURIComponent(
																			params.id,
																		)}
																		categoryId={decodeURIComponent(
																			params.cat_id,
																		)}
																		candidacyId={decodeURIComponent(
																			params.candidacy_id,
																		)}
																	/>
																)}
															/>
															<Route
																path="/awards/:id/about_us"
																exact
																component={(props) => (
																	<AboutUs.component
																		id={decodeURIComponent(
																			props.match.params.id,
																		)}
																	/>
																)}
															/>
															<Route
																path="/awards/:id/instructions"
																exact
																component={(props) => (
																	<Instructions.component
																		id={decodeURIComponent(
																			props.match.params.id,
																		)}
																	/>
																)}
															/>
															<Route
																path="/awards/:id/prize_gala"
																exact
																component={(props) => (
																	<PrizeGala.component
																		id={decodeURIComponent(
																			props.match.params.id,
																		)}
																	/>
																)}
															/>
															<Redirect
																to='/award-selection'
															/>
														</Switch>
													</GridColumn>
												</Grid>
											</main>
											<Footer />
										</div>
										{this.state.awardId && !this.state.isRating && <SidebarBlock awardId={this.state.awardId} />}
									</div>
								</SidebarPusher>
							</SidebarPushable>
						</section>
					);
				}}
			</UserData>
		);
	}
}

export default withRouter(LoggedRouter);

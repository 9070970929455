import { CONFIG } from '../static/config';
import { ROLES } from '@food/auth';

const plainConfig = CONFIG[window.location.hostname];

const authConfig = {
	TARGET_SITE: plainConfig.TARGET_SITE || 'VOTE',
	AUTH_ENDPOINT: plainConfig.API_ENDPOINT.AUTH,
	LOGIN_START_TRADITIONAL:
		plainConfig.LOGIN_START_TRADITIONAL === undefined
			? false
			: plainConfig.LOGIN_START_TRADITIONAL,
	LOGO: plainConfig.LOGO || '/imgs/logo.svg',
	AUTH_ROLES: plainConfig.AUTH_ROLES || [ROLES.BUYER],
	LANG: plainConfig.LANG || 'it_IT',
};

export { authConfig };

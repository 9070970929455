import React from 'react';
import { config } from '../static/config';
import {
	Button,
	Grid,
	GridColumn,
	GridRow,
	Header,
	Icon,
	Segment,
} from 'semantic-ui-react';
import { queryErrorHandler } from '../utils/errorHandlers';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import t from '../utils/labels';
import RateStatistic from './RateStatistic';
import { LightBoxedImage } from './lightBoxedImage';
import { GenericSpinner } from './GenericSpinner';
import '../reportPrint.css';

// query
const CompletedCategoryQuery = gql`
	query CompletedCategoryQuery($first: Int, $id: ID!) {
		topRatings: candidacyRatings(
			filter: { AwardCategory: { id: $id } }
			first: $first
		) {
			edges {
				node {
					id
					Candidacy {
						id
						localId
						name
						description1
						description3
						featuredImageMedia {
							id
							largeThumbUrl
							mediumThumbUrl
							smallThumbUrl
						}
						Company {
							id
							name
							slug
						}
						goods {
							id
							slug
							name
						}
					}
					overall
					rating1
					rating2
					rating3
					rating4
					rating5
				}
			}
		}
		category: node(id: $id) {
			... on AwardCategory {
				id
				name
				description
				Award {
					id
					name
					year
					rating1Label
					rating2Label
					rating3Label
					rating4Label
					rating5Label
					ratingOverallLabel
				}
			}
		}
	}
`;

// component
const AwardCategoryCompletedView = ({
	data: { loading, error, category, topRatings },
}) => {
	if (loading) return <GenericSpinner />;
	if (error) return queryErrorHandler(error);

	const ratings = topRatings ? topRatings.edges.map((n) => n.node) : [];
	const availableRatings = [1, 2, 3, 4, 5].filter(
		(index) => category.Award[`rating${index}Label`],
	);

	const overallLabel = category.Award['ratingOverallLabel'] || t`Overall`;

	const candidacyData = (candidacy) => {
		return (
			<Header.Subheader>
				<div>
					{t`Supplier`}: &nbsp;
					<a
						href={
							'https://www.italianfood.net/suppliers/' +
							candidacy.Company.slug
						}
						target={'_blank'}
						className={'report-ifn-link'}
						rel={'noopener noreferrer'}
					>
						{candidacy.Company.name}&nbsp;
						<Icon name={'external square alternate'} />
					</a>
				</div>
				{candidacy.goods.length === 1 && (
					<div>
						{t`Product`}:&nbsp;{' '}
						<a
							href={
								'https://www.italianfood.net/goods/' +
								candidacy.goods[0].slug
							}
							className={'report-ifn-link'}
						>
							{candidacy.goods[0].name}&nbsp;
							<Icon name={'external square alternate'} />
						</a>
					</div>
				)}
				{candidacy.goods.length > 1 && (
					<div>
						{t`Products`}:&nbsp;{' '}
						{candidacy.goods.map((good) => (
							<a
								href={'https://www.italianfood.net/goods/' + good.slug}
								target={'_blank'}
								className={'report-ifn-link-multi'}
								rel={'noopener noreferrer'}
							>
								{good.name} <Icon name={'external square alternate'} />
							</a>
						))}
					</div>
				)}
			</Header.Subheader>
		);
	};

	return (
		<section data-entity="award-category-detail">
			<Segment className="margin-top" padded>
				<Grid>
					<GridRow className={'equal width'}>
						<GridColumn>
							<Header as="h2" size="large">
								<Icon name="trophy" />
								<Header.Content>
									{category.name}
									<Header.Subheader className="mobile hidden">
										{category.description}
									</Header.Subheader>
								</Header.Content>
							</Header>
						</GridColumn>
						<GridColumn className={'right aligned noprint'}>
							{window.print && (
								<Button
									icon
									labelPosition={'left'}
									color={'green'}
									onClick={() => window.print()}
								>
									{t`Print report`} <Icon name="download" />
								</Button>
							)}
						</GridColumn>
					</GridRow>
				</Grid>
			</Segment>
			<Segment padded>
				{ratings.length === 50 && (
					<Header
						as="h5"
						dividing
						content={
							config('TOP_RATINGS_SHOWED') + ' ' + t`top rated products`
						}
					/>
				)}
				{ratings.length > 50 && (
					<Header as="h5" dividing content={t`top rated products`} />
				)}

				<Grid divided="vertically" className={'report'}>
					{ratings.map((r) => (
						<GridRow key={r.id}>
							<GridColumn
								largeScreen={3}
								computer={3}
								tablet={4}
								mobile={16}
								className="text-centered"
							>
								<LightBoxedImage {...r.Candidacy.featuredImageMedia} />
							</GridColumn>
							<GridColumn
								computer={13}
								largeScreen={13}
								tablet={12}
								mobile={16}
							>
								<Grid>
									<GridRow>
										<GridColumn
											largeScreen={6}
											computer={6}
											tablet={6}
											mobile={16}
										>
											<Header as="h2" className={'candidacy-data'}>
												{r.Candidacy.name}
											</Header>
											<div className={'mobile only padded-bottom'}>
												{candidacyData(r.Candidacy)}
											</div>
										</GridColumn>
										<GridColumn
											largeScreen={10}
											computer={10}
											tablet={10}
											mobile={16}
										>
											<Grid>
												<GridColumn
													textAlign="center"
													computer={4}
													largeScreen={8}
													tablet={8}
													mobile={8}
												>
													<RateStatistic
														label={overallLabel}
														value={r.overall}
													/>
												</GridColumn>
												{availableRatings.map((index) => {
													const ratingLabel = category.Award[
														`rating${index}Label`
													]
														.split('|')[0]
														.replace('*', '')
														.replace('$', '');
													return (
														<GridColumn
															key={index}
															textAlign="center"
															computer={4}
															largeScreen={8}
															tablet={8}
															mobile={8}
														>
															<RateStatistic
																value={r[`rating${index}`]}
																label={ratingLabel}
															/>
														</GridColumn>
													);
												})}
											</Grid>
										</GridColumn>
									</GridRow>
									<GridRow className={'mobile hidden noprint'}>
										<GridColumn>
											{candidacyData(r.Candidacy)}
										</GridColumn>
									</GridRow>
									<GridRow className={'mobile hidden'}>
										<GridColumn>
											{t`Caratteristiche innovative del prodotto:`}
											<div dangerouslySetInnerHTML={{__html:r.Candidacy.description1}}></div>
										</GridColumn>
									</GridRow>
								</Grid>
							</GridColumn>
						</GridRow>
					))}
				</Grid>
			</Segment>
		</section>
	);
};

export default graphql(CompletedCategoryQuery, {
	options: ({ categoryId }) => ({
		variables: {
			id: categoryId,
			first: config('TOP_RATINGS_SHOWED'),
		},
	}),
})(AwardCategoryCompletedView);

import React from 'react';
import t from '../utils/labels';
import { Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react';
import { config } from '../static/config';

export const AuthContainer = ({ children }) => (
	<div id="main-wrapper">
		<main id="main-content">
			<Grid padded centered id="login">
				<GridColumn
					largeScreen={8}
					widescreen={7}
					computer={10}
					tablet={10}
					mobile={16}
				>
					<Segment style={{ padding: 0, paddingBottom: '1em' }} className={'login-container'}>
						<Grid>
							<GridRow className={'no-padding-bottom'}>
								<GridColumn
									computer={16}
									tablet={16}
									mobile={16}
									className="text-centered"
								>
									{children}
								</GridColumn>
							</GridRow>
							<GridRow className={'no-padding-top'}>
								<GridColumn width={16} className="login-intro">
									<p>
										{t`contact intro`}{' '}
										<a href={'mailto:' + config('HELP_EMAIL')}>
											{config('HELP_EMAIL')}
										</a>
									</p>
								</GridColumn>
							</GridRow>
						</Grid>
					</Segment>
				</GridColumn>
			</Grid>
		</main>
	</div>
);

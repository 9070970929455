export default {
	Menu: 'Menu',
	home: 'Home',
	overall: 'Sales potential',
	close: 'Close',
	'options.email error': 'Wrong user/password',
	'options.password error': 'Wrong user/password',
	instructions: 'How it works',
	'prize gala': 'Prize gala',
	'about us': 'About us',
	Logout: 'Logout',
	Loading: 'Loading',
	Copyright: 'Copyright',
	'remaining days': 'Days left to vote',
	'remaining categories': 'Pending categories',
	'browse the magazine': 'Browse the magazine',
	info: 'Info',
	report: 'Report',
	start: 'Start',
	'few products remaining': 'Almost completed!',
	'few products remaining description':
		'Few products left to get a report of your ratings',
	'stay on this category': 'Stay here',
	'leave page': 'Leave this category',
	'more info': 'More info',
	'launch date': 'Launch date',
	ratings: 'Ratings',
	'vote next': 'Rate next',
	'shelf life': 'Shelf life',
	regulation: 'Terms and Conditions',
	'thanks for your ratings!': 'Thanks for your ratings!',
	'now go elsewhere': 'See your ratings report',
	'return dashboard': 'Back to Home',
	'see results': 'See Results',
	email: 'email',
	password: 'password',
	login: 'Login',
	'contact intro': 'For any enquiries, please contact',
	'Clausole vessatorie': 'Unfair terms',
	'I accept clausole vessatorie (required)':
		'I accept the Unfair Terms (required)',
	'Waiting Review': 'User waiting review',
	'Waiting review explanation text':
		"Your company's email domain hasn't been classified in our system yet. The process of verification may take from a few minutes to a day. We'll send you an email of confirmation as soon as the process will be completed. Thanks for your patience.",
	'Food footer': 'Food s.r.l. - all rights reserved',
	'Role BUYER': 'Yes, I understand',
	'Registration intro':
		'The voting process is reserved to Retailers.<br />Our system and staff will verify your work email address in order to confirm you can vote.',
	'Wrong authentication title': 'Access denied',
	'Wrong authentication text':
		"Either the event is not open for voting or you don't have permission to vote.",
	'alreadyUsedEmail error':
		'This email has already been used, try with another one',
	'wrongEmailFormat error': 'The email format is not valid',
	'grantToken error':
		'Cannot obtain requested data from your social network. Please check your settings and, if the problem persists, please use another social network.',
	'grantToken.email error':
		'Cannot obtain the email from your social network. Please check your settings and, if the problem persists, use another social network.',
	'grantToken.email facebook error':
		'Cannot obtain the email from your social network. Please <a target="_blank" href="https://www.facebook.com/settings">check your settings</a> and, if the problem persists, use another social network.',
	'producers not allowed': 'Restricted Area',
	'producers not allowed text': 'Rating area is restricted to GDO Buyers only',
	'not voted': 'Not rated',
	'USER_NOT_FOUND/Error': 'Authentication error',
	'USER_NOT_FOUND/HelpText': 'Check your access data',
	'wrong award status/Error': 'Award not accessible',
	'wrong award status/HelpText': 'Award not open for rating',
	continue: 'Continue',
	'Sign In': 'Sign In',
	// 'Switch to Social Account': '',
	'switch to social explanation title':
		'We are deprecating accounts with password',
	// 'Associate a social account to your user': '',
	'Maybe later': 'Maybe later',
	'switch to social explanation html': `Connect your social account and log in with a only a click, without having to keep your credentials in mind.`,
	'Associate a social account to your user':
		'Select a social account to associate it with your user',
	'generic error':
		'A problem has occurred<br />Please try again in a few moments',
	'remember switch to social title': 'Social Account associated',
	'remember social account html':
		'Remember that you can log in using your social account, without having to remember your email and password.',
	'social login type associated': 'Social account associated',
	proceed: 'Proceed',
	'consumer welcome title': 'Access Forbidden',
	'producer welcome title': 'Access Forbidden',
	'consumer welcome html 1':
		"We're sorry, only members of US retail and foodservice can vote.<br />If you're one of them please upgrade your registration. It's fast and free.",
	"if you're not": "If you're not",
	'change account': 'change account',
	'producer welcome html': `We're sorry, only members of US retail can vote.<br />
You're registered with a company that is not part of it.<br />`,
	"if you think there's a problem with your account":
		"If you think there's a problem with your account",
	'producer wrong job text': 'If we made a mistake or you changed job please',
	'Waiting review explanation html':
		'<p>Your user has been created and your email verified.</p><p>To grant you full access to the platform tools is still necessary that we create your company profile. You will be notified at the end of our controls.</p>',
	'No awards title': 'There are no voting sessions open at the moment',
	'No awards text': 'We are defining our next award, come back to this page later and discover it!',
	'status_VOTING_OPEN': 'Open Voting',
	'status_VOTING_CLOSED': 'Closed Voting',
	'award_selection_title': 'Select an Award',
	'vote_cta': 'Vote now',
	'view_cta': 'View'
};

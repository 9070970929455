import React from 'react';
import { Dropdown, Icon, Menu, MenuItem, MenuMenu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import t from '../utils/labels';
import { UserData } from '@food/auth';
//import { history } from '../utils/history';

const Topbar = ({ showOnDesktop, toggler }) => (
	<UserData>
		{({userData}) => {
			const name= userData.name;
			
			return (
				<Menu color="red" inverted borderless id="topbar">
					{toggler !== false && 
						<MenuMenu className={showOnDesktop?'':'mobile tablet only'}>
							<MenuItem onClick={toggler}>
								<Icon name="content" />
							</MenuItem>
						</MenuMenu>
					}
					<Menu.Menu position="right">
						<Dropdown text={name} className="link item">
							<Dropdown.Menu>
								<Dropdown.Item
									as={Link}
									to="/logout"
								>{t`Logout`}</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Menu.Menu>
				</Menu>
			);
		}}
	</UserData>
);

export default Topbar;
